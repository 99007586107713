import styled from "@emotion/styled"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import { breakpoint } from "../../theme/theme"
import { getSlugFromTitle } from "../../utils/slug"
import { Button } from "../Button/Button"
import { getGradient, Paper, PaperProps } from "../Paper/Paper"
import { PlainLink } from "../PlainLink/PlainLink"
import { Typography } from "../Typography/Typography"

interface CaseStudyCardProps {
  company: string
  title: string
  subtitle: string
  color: PaperProps["color"]
  coverImage: IGatsbyImageData
}

export const CaseStudyCard: React.FC<CaseStudyCardProps> = ({
  company,
  title,
  subtitle,
  color,
  coverImage,
}) => {
  const slug = getSlugFromTitle(title)
  return (
    <StyledPaper color={color} gradient>
      <Container>
        <Typography variant="caption">{company}</Typography>
        <Typography variant="h5">{title}</Typography>
        <Typography variant="p">{subtitle}</Typography>
        <PlainLink to={`/work/${slug}`}>
          <Button>Read Case Study</Button>
        </PlainLink>
      </Container>
      <ImageContainer>
        <GatsbyImage image={coverImage} alt={title} />
      </ImageContainer>
    </StyledPaper>
  )
}

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "60px 0px",
  gap: "1em",
})

const StyledPaper = styled(Paper)(({ color, theme }) => ({
  display: "grid",
  padding: "1em",
  borderRadius: "12px",
  position: "relative",
  "::before": {
    borderRadius: "12px",
    content: "''",
    position: "absolute",
    zIndex: -1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    clipPath: "polygon(0 0, 100% 0, 100% 70%, 0% 100%)",
    background: getGradient(theme, color),
  },
  [breakpoint("md")]: {
    gridTemplateColumns: "1fr 1fr",
    background: "transparent",
  },
}))

const ImageContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
})
