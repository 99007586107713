import { graphql } from "gatsby"
import { FeaturedCaseStudies } from "../FeaturedCaseStudies/FeaturedCaseStudies"

export const query = graphql`
  query featuredCaseStudiesQuery {
    workPage: markdownRemark(fileAbsolutePath: { regex: "/netlify/work.md/" }) {
      frontmatter {
        title
        featuredCaseStudies
      }
    }
    site {
      ...SiteFragment
    }
    header: markdownRemark(fileAbsolutePath: { regex: "/netlify/header.md/" }) {
      ...HeaderFragment
    }
    footer: markdownRemark(fileAbsolutePath: { regex: "/netlify/footer.md/" }) {
      ...FooterFragment
    }

    caseStudies: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/netlify/case_studies/" } }
    ) {
      nodes {
        fileAbsolutePath
        frontmatter {
          title
          caption
          client
          color
          coverImage {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
`
export default FeaturedCaseStudies
