import React from "react"
import { PageProps } from "gatsby"
import { Typography } from "../components/Typography/Typography"
import { BasePageData, withLayout } from "../components/Layout/layout.hocs"
import { getImage, IGatsbyImageData, ImageDataLike } from "gatsby-plugin-image"
import { getFilteredFeatures } from "../utils/slug"
import styled from "@emotion/styled"
import { Paper } from "../components/Paper/Paper"
import { CaseStudyCard } from "../components/CaseStudyCard/CaseStudyCard"
import { breakpoint } from "../theme/theme"

export interface CaseStudy {
  title: string
  caption: string
  client: string
  coverImage: ImageDataLike
  color: "blue" | "darkblue" | "green" | "purple"
}

interface FeaturedCaseStudiesData extends BasePageData {
  workPage: {
    frontmatter: {
      title: string
      featuredCaseStudies: Array<string>
    }
  }
  caseStudies: {
    nodes: Array<{
      fileAbsolutePath: string
      frontmatter: CaseStudy
    }>
  }
}

type FeaturedCaseStudiesProps = PageProps<FeaturedCaseStudiesData>

export const FeaturedCaseStudies: React.FC<FeaturedCaseStudiesProps> =
  withLayout({ title: "Our Work", variant: "light" }, (props) => {
    const { title } = props.data.workPage.frontmatter
    const featuredCaseStudies =
      props.data.workPage.frontmatter.featuredCaseStudies
    const caseStudies = getCaseStudies(
      getFilteredFeatures(featuredCaseStudies, props.data.caseStudies.nodes)
    )

    return (
      <>
        <StyledPaper color="blue" gradient>
          <Typography variant="h1">{title}</Typography>
        </StyledPaper>
        <Container>
          {caseStudies.map(
            ({ client, caption, color, coverImage, title }, index) => {
              return (
                <CaseStudyCard
                  company={client}
                  subtitle={caption}
                  color={color}
                  coverImage={coverImage}
                  key={`${client}-${index}`}
                  title={title}
                />
              )
            }
          )}
        </Container>
      </>
    )
  })

const getCaseStudies = (caseStudies: CaseStudy[]) => {
  return caseStudies.map(({ coverImage, ...other }) => {
    const image: IGatsbyImageData | undefined = process.env.STORYBOOK
      ? (coverImage as IGatsbyImageData)
      : getImage(coverImage)

    if (!image) {
      throw new Error(`No image found for ${coverImage}`)
    }

    return { ...other, coverImage: image }
  })
}

const StyledPaper = styled(Paper)({
  display: "flex",
  flexDirection: "column",
  gap: "1em",
  padding: "8em 2em 4em",
  [breakpoint("lg")]: {
    padding: "8em 8em 4em",
  },
})

const Container = styled("div")({
  display: "grid",
  gridRowGap: "10em",
  padding: "5em 2em",
  maxWidth: "1200px",
  margin: "auto",
})
