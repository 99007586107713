// Transforms the title of the page into a url slug with spaces separted by dashes.
export const getSlugFromPath = (fileAbsolutePath: string) => {
  const title = getTitle(fileAbsolutePath)
  // Convert the title to a slug
  const slug = title.toLowerCase().replace(/\s+/g, "-")

  // Return the slug
  return slug
}
// Transforms the title of the page into a url slug with spaces and commas replaced by dashes.
// The ampersand is replaced with a dash.
export const getSlugFromTitle = (title: string) => {
  // Convert the title to a slug
  const slug = title
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/,/g, "")
    .replace(/&/g, "-")
    .replace(/-+/g, "-")

  // Return the slug
  return slug
}

const getTitle = (fileAbsolutePath: string): string => {
  // Get the title from the file name
  const filename = fileAbsolutePath.split("/").pop()?.split(".")[0]
  const title = filename

  // Return the title
  return title || ""
}

/**@summary Converts the featured case studies into an array of slugs using getSlug. Then filters the array of caseStudies by converting fileAbsolutePath into a slug */
export const getFilteredFeatures = <T>(
  featured: Array<string>,
  options: Array<{ fileAbsolutePath: string; frontmatter: T }>
): Array<T> => {
  const featuredSlugs = featured.map((title) => getSlugFromTitle(title))
  const filteredOptions = options
    .filter(({ fileAbsolutePath }) =>
      featuredSlugs.includes(getSlugFromPath(fileAbsolutePath))
    )
    .map(({ frontmatter }) => frontmatter)

  if (filteredOptions.length < featuredSlugs.length) {
    throw new Error(
      "The length of the filtered options is less than the length of the featured slugs"
    )
  }

  return filteredOptions
}
