import { PageProps } from "gatsby"
import { Layout } from "./Layout"
import React from "react"
import {
  SiteGraphQLData,
  HeaderGraphQLData,
  FooterGraphQLData,
} from "./layout.types"
import { getHeaderProps, getFooterProps } from "./layout.utils"

export interface BasePageData {
  site: SiteGraphQLData
  header: HeaderGraphQLData
  footer: FooterGraphQLData
}

type BasePageProps = PageProps<BasePageData>

type PageConfiguration = {
  title: string
  variant: "light" | "dark"
}

export function withLayout<T extends BasePageProps>(
  config: PageConfiguration,
  WrappedComponent: React.FC<T>
): React.FC<T> {
  const PageComponent: React.FC<T> = (props) => {
    const siteData = props.data.site.siteMetadata
    const headerProps = props.data.header
    const footerProps = props.data.footer
    return (
      <Layout
        title={`${config.title} | ${siteData.title}`}
        description={siteData.description}
        header={getHeaderProps(headerProps, config.variant)}
        footer={getFooterProps(footerProps)}
      >
        <WrappedComponent {...props} />
      </Layout>
    )
  }
  return PageComponent
}
