import styled from "@emotion/styled"
import React from "react"

export interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  variant?: "filled" | "outlined"
}

export const Button = styled.button<ButtonProps>(
  ({ theme, variant = "filled" }) => ({
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize.button,
    color: variant === "filled" ? "black" : "white",
    backgroundColor: variant === "filled" ? "white" : "transparent",
    cursor: "pointer",
    textTransform: "uppercase",
    fontWeight: 800,
    padding: "14px 20px",
    borderRadius: "3px",
    border: variant === "filled" ? "none" : "1px solid white",
  })
)
